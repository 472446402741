import classNames from 'classnames';
import type { PropsWithChildren } from 'react'

type SubTitleProps = { 
  className?: string;
}

export const SubTitle = ( { className, children }: PropsWithChildren<SubTitleProps> ) => {
  return (
    <p className={ classNames(
      'lg:text-xl drop-shadow-[0_4px_4px_rgba(25,14,61,0.30)] text-[#ABB0C7] font-light text-center text-pretty',
      className
    ) }>
      { children }
    </p>
  )
}
